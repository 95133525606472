import { useEffect, useState } from "react";

import { DateTime } from "luxon";

import { useDebounce } from "@uidotdev/usehooks";
import { BellIcon, MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";

import useLeads from "../../data/useLeads";
import useTrips from "../../data/useTrips";
import { ITruck } from "../../models/Truck";
import useTrucks from "../../data/useTrucks";
import classNames from "../../utils/classNames";
import { ITripsResponse } from "../../models/Trip";
import { ILead } from "../../models/Lead";
import { INote, ResourceType } from "../../models/Note";
import formatDateTime from "../../utils/formatDateTime";
import useLeadsFromIds from "../../data/useLeadsFromIds";
import TripSlot from "../../components/tripManager/TripSlot";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { IUnavailableDate } from "../../models/UnavailableDate";
import useLeadsWithFilters from "../../data/useLeadsWithFilters";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import CalendarDayMap from "../../components/calendar/CalendarDayMap";
import PrintJobSheet from "../../components/tripManager/PrintJobSheet";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import UnbookedEvents from "../../components/tripManager/UnbookedEvents";
import TripManagerNotes from "../../components/tripManager/TripManagerNotes";
import useNotification from "../../components/notifications/useNotifications";

const sortingOptions = ["Postcode A-Z", "VRM A-Z", "Oldest to Newest", "Newest to Oldest"];

const dayColours = ["bg-red-100 ", "bg-orange-100 ", "bg-green-100 ", "bg-blue-100 ", "bg-purple-100 ", "bg-teal-100 ", "bg-lime-100 "];
const truckColours = ["bg-red-200 ", "bg-orange-200 ", "bg-green-200 ", "bg-blue-200 ", "bg-purple-200 ", "bg-teal-200 ", "bg-lime-200 "];

export interface Distances {
  green: string[],
  amber: string[],
  red: string[],
};

export default function TripManager() {
  const { trucks } = useTrucks();
  const [yearNumber, setYearNumber] = useState<number>(DateTime.now().year);
  const [weekNumber, setWeekNumber] = useState<number>(DateTime.now().weekNumber);
  const { trips: calendarTrips, create } = useTrips(yearNumber, weekNumber);
  const { setInfo } = usePageNameContext();
  const { distances: allDistances, getAllCollectionNotes } = useLeads(true);
  const { saveHandlers } = useDefaultCRUDHandlers("Lead");
  const { addNotification } = useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notesOpen, setNotesOpen] = useState<boolean>(false);
  const [notesDate, setNotesDate] = useState<string | undefined>(undefined);
  const [notesUnavailableDates, setNotesUnavailableDates] = useState<IUnavailableDate[]>([]);
  const [notesTruck, setNotesTruck] = useState<ITruck | undefined>(undefined);
  const [isCollectionNoteLoading, setIsCollectionNoteLoading] = useState<boolean>(false);
  const [unbooked, setUnbooked] = useState<ILead[]>([]);
  const [dayLeads, setDayLeads] = useState<ILead[]>([]);
  const [trips, setTrips] = useState<ITripsResponse[]>([]);

  const [refresh, setRefresh] = useState<boolean>(true);
  const [showWeekend, setShowWeekend] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState("Newest to Oldest");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedDay, setSelectedDay] = useState<string | undefined>("");
  const [selectedTrip, setSelectedTrip] = useState<string | undefined>("");
  const [selected, setSelected] = useState<ILead | undefined>(undefined);
  const [distances, setDistances] = useState<Distances>();

  const searchDebounced = useDebounce([searchTerm], 500);

  const { leadsWithFilters: unbookedLeads } = useLeadsWithFilters({ query: searchDebounced[0], pageFilter: 1 });
  // const { leadsWithFilters: uncollectedLeads } = useLeadsWithFilters({ query: "", pageFilter: 2 }); //Replaced with leadsFromIds
  const { leadsFromIds } = useLeadsFromIds(calendarTrips.isLoading ? [] : calendarTrips.data?.data.map(trip => trip.trips).flat().map(x => x.leads).flat()!);

  useEffect(() => {
    setInfo({
      name: "Trip Manager",
      desc: "View and manage your lead collections in a trip view",
    });
  }, []);

  useEffect(() => {
    if (!unbookedLeads.isLoading && unbookedLeads.data &&
      !leadsFromIds.isLoading && leadsFromIds.data &&
      !trucks.isLoading && trucks.data &&
      !calendarTrips.isLoading && calendarTrips.data) {

      let _unbookedLeads = sortBy(unbookedLeads.data?.data.leads!);

      let dayLeads: ILead[] = [];

      //Depending on whether you have a day or trip selected, filter the leads to show on map
      if (selectedDay !== undefined) {
        dayLeads = leadsFromIds.data?.data.leads.filter((lead: ILead) =>
          lead.vehicle?.scheduledCollectionOn === selectedDay
        )!;
      } else if (selectedTrip !== undefined) {
        dayLeads = leadsFromIds.data?.data.leads.filter((lead: ILead) =>
          lead.tripId === selectedTrip
        )!;
      }

      setDayLeads(dayLeads);
      setUnbooked(_unbookedLeads)
      setTrips(calendarTrips.data.data);
      setIsLoading(false);
    }
  },
    [
      unbookedLeads.isLoading,
      unbookedLeads.data,
      leadsFromIds.isLoading,
      leadsFromIds.data,
      trucks.isLoading,
      trucks.data,
      calendarTrips.isLoading,
      calendarTrips.data,
      weekNumber,
      searchTerm,
      currentFilter,
      selected,
      selectedDay,
      selectedTrip,
      showWeekend,
      refresh
    ]);

  useEffect(() => {
    if (!calendarTrips.isLoading && calendarTrips.data) {
      //if trips on Satuday or Sunday then check show weekend by default
      if (calendarTrips.data?.data.find(trip =>
        (DateTime.fromISO(trip.date).weekday === 6 && trip.trips.length > 0) ||
        (DateTime.fromISO(trip.date).weekday === 7 && trip.trips.length > 0))) {
        setShowWeekend(true);
      }
    }
  }, [calendarTrips.isLoading, calendarTrips.data])

  const sortBy = (leads: ILead[]) => {
    if (currentFilter === "Postcode A-Z") {
      return leads?.sort((a, b) => {
        return (a.vehicle?.postcode! > b.vehicle?.postcode! ? 1 : -1);
      });
    } else if (currentFilter === "VRM A-Z") {
      return leads?.sort((a, b) => {
        return (a.vehicle?.vrm! > b.vehicle?.vrm! ? 1 : -1);
      });
    } else if (currentFilter === "Oldest to Newest") {
      return leads?.sort((a, b) => {
        return (new Date(a.created!).getTime() >= new Date(b.created!).getTime() ? 1 : -1);
      });
    } else if (currentFilter === "Newest to Oldest") {
      return leads?.sort((a, b) => {
        return (new Date(a.created!).getTime() <= new Date(b.created!).getTime() ? 1 : -1);
      });
    } else {
      return leads;
    }
  }

  const getPostcodeAreas = () => {
    type PostcodeCounts = Record<string, number>;

    const postcodeCounts: PostcodeCounts = {};

    unbooked?.forEach((lead) => {
      const postcodeArea = lead.vehicle!.postcode!.slice(0, 2); // Get the first two characters of the postcode
      if (postcodeCounts[postcodeArea]) {
        postcodeCounts[postcodeArea] += 1; // Increment the count if we've already seen this postcode area
      } else {
        postcodeCounts[postcodeArea] = 1; // Otherwise, start the count at 1
      }
    });

    const postcodeStrings = Object.entries(postcodeCounts)
      .sort(([postcodeA], [postcodeB]) => postcodeA.localeCompare(postcodeB))
      .map(([postcodeArea, count]) => `<strong>${postcodeArea}: ${count}</strong>`);

    const outputString = `${postcodeStrings.join(' | ')}`;

    return outputString;
  }

  const handleDayTripSelect = (day?: string, trip?: string) => {
    let newSelectedDay = day;
    let newSelectedTrip = trip;

    if (day === selectedDay) {
      newSelectedDay = undefined;
    }

    if (trip === selectedTrip) {
      newSelectedTrip = undefined;
    }

    setSelectedDay(newSelectedDay);
    setSelectedTrip(newSelectedTrip);
  }

  const handleSelected = (lead: ILead) => {
    if (lead === undefined || selected?.id === lead.id) {
      setSelected(undefined);
      setDistances(undefined);
      return;
    } else {
      setSelected(lead);
    }

    allDistances.mutate(lead.id, {
      onSuccess: (data: any) => {
        setDistances(data.data);
      },
      onError: () => {
        console.log("Error");
        saveHandlers.onError();
      }
    })
  }

  const handleWeekChange = (next: boolean) => {

    if (next) {
      if (weekNumber === 52) {
        setYearNumber(yearNumber + 1);
        setWeekNumber(1);
      } else {
        setWeekNumber(weekNumber + 1);
      }

    } else {
      if (weekNumber === 1) {
        setYearNumber(yearNumber - 1);
        setWeekNumber(52);
      } else {
        setWeekNumber(weekNumber - 1);
      }
    }
  }

  const getTodaysEvents = () => {
    //filter the events to only include the current day
    return leadsFromIds.data?.data.leads?.filter((lead: ILead) =>
      lead.vehicle?.scheduledCollectionOn === selectedDay
    );
  }

  const handleCollectionNotes = () => {
    setIsCollectionNoteLoading(true);
    const todaysEvents = getTodaysEvents();

    let guids: any = [];

    todaysEvents?.forEach((lead: ILead) => {
      guids.push(lead?.id);
    });

    if (guids.length === 0) {
      addNotification({
        variant: "warn",
        primaryText: "No leads to print",
      });
      setIsCollectionNoteLoading(false);
      return;
    }

    let body = {
      leads: guids,
    }

    getAllCollectionNotes.mutate(body, {
      onSuccess: (file) => {
        const url = window.URL.createObjectURL(file);
        const w = window.open(url, "_blank");
        w?.print();
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => {
        setIsCollectionNoteLoading(false);
      }
    });
  };

  const handleAddTrip = (date: string, truckId: string) => {
    let body = {
      date: date,
      truckId: truckId,
    }

    create.mutate(body, {
      onSuccess: () => {
        saveHandlers.onSuccess();
      },
      onError: () => {
        saveHandlers.onError();
      },
      onSettled: () => {
        setRefresh(!refresh);
      }
    });
  }

  const handleNotesOpen = (truck: ITruck, date: string, unavailableDates: IUnavailableDate[]) => {
    setNotesOpen(true);
    setNotesDate(date);
    setNotesUnavailableDates(unavailableDates);
    setNotesTruck(truck);
  }

  const displayNoteAlert = (notes: INote[], truck: ITruck) => {
    let driverNotes = notes.filter((note) => note.resourceType === ResourceType.Driver && note.resourceId === truck.driverId);
    let truckNotes = notes.filter((note) => note.resourceType === ResourceType.Truck && note.resourceId === truck.id);

    if (driverNotes.length > 0 || truckNotes.length > 0) {
      return <span className="pt-1 text-red-500"><BellIcon className="rotate-90 h-5 w-5" /></span>
    } else {
      return <span className="pt-1"><div className="h-5 w-5"> </div></span>
    }
  }

  if (isLoading) {
    return <LoadingWheel />;
  } else {

    return (
      <>
        <TripManagerNotes open={notesOpen} setOpen={setNotesOpen} truck={notesTruck} date={notesDate!} unavailableDates={notesUnavailableDates} year={yearNumber} week={weekNumber} />
        <div className="mt-2 w-full flex justify-end">
          <div className="flex justify-center items-center">
            <input type="checkbox" checked={showWeekend} onChange={() => setShowWeekend(!showWeekend)} />
            <label className="ml-2">Show weekend</label>
          </div>
        </div>
        <div className="mt-4">

          <div className="w-full px-1 flex items-center justify-between">
            <div className="">
              <select aria-label="Unscheduled Leads Sorter"
                onChange={(e) => setCurrentFilter(e.target.value)}
                defaultValue={currentFilter}
                className="text-sm">
                {sortingOptions.map((filter) => (
                  <option
                    className="text-sm"
                    key={filter}>
                    {String(filter)}
                  </option>
                ))}
              </select>
            </div>

            <p className="text-lg text-center font-semibold">Unscheduled Leads - {unbooked.length}</p>

            <div className="focus-within:z-10">
              <div className="absolute pt-2 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 sm:text-sm"
                placeholder="VRM, Postcode or Name"
              />
            </div>
          </div>

          <div className="flex justify-center items-center">
            <p className="mr-6"><span dangerouslySetInnerHTML={{ __html: getPostcodeAreas() }}></span></p>
          </div>

          <div className="mt-2 max-h-[15vh] overflow-y-auto">
            <UnbookedEvents unbookedLeads={unbooked} selected={selected} handleSelected={handleSelected} year={yearNumber} week={weekNumber} />
          </div>

          <div className="mt-4 flex justify-between text-center">
            <div>
              <ButtonInput label={"<"} isSubmit={false} onClick={() => handleWeekChange(false)} classes={"px-4 text-xl font-bold"} />
            </div>
            <h1 className="text-xl font-semibold">
              {`${formatDateTime(trips[0].date, DateTime.DATE_MED_WITH_WEEKDAY)} - ${formatDateTime(trips[trips.length - 1].date, DateTime.DATE_MED_WITH_WEEKDAY)} `}
            </h1>
            <div>
              <ButtonInput label={">"} isSubmit={false} onClick={() => handleWeekChange(true)} classes={"px-4 text-xl font-bold"} />
            </div>
          </div>

          <div className="mt-2 w-full text-center bg-blue-100 border border-gray-700">
            <h1 className="text-lg">Trips</h1>
          </div>

          <div className="max-h-[70vh] overflow-y-auto">
            <div className="grid grid-rows-5">
              {/* Return days of the week */}
              {trips.map((day, i) => {
                if (!showWeekend && (DateTime.fromISO(day.date).weekday === 6 || DateTime.fromISO(day.date).weekday === 7)) {
                  return;
                }
                return (
                  <div key={i} className="grid grid-flow-col auto-cols-max">
                    <div onClick={() => handleDayTripSelect(day.date, undefined)}
                      className={classNames(selectedDay === day.date ? "bg-green-200" : dayColours[i], "rotate-180 text-center text-lg font-semibold border border-gray-700 cursor-pointer")}
                      style={{ writingMode: "vertical-rl" }}
                    >
                      {formatDateTime(day.date, DateTime.DATE_MED_WITH_WEEKDAY)}
                    </div>

                    <div className="divide-y-2 border-b-2 border-gray-500">
                      {/* Show all the trucks for each day */}
                      {trucks.data?.data.map((truck, j) => {
                        const unavailableNotes = day.unavailableDates.filter((date: IUnavailableDate) => date.resourceId === truck.id || date.resourceId === truck.driverId);
                        const unavailable = unavailableNotes.length > 0;
                        // console.log(truck.capacity)
                        return (
                          <div key={j} className="flex">
                            <div
                              className={classNames(truckColours[i], unavailable ? "!bg-gray-300" : "", "flex justify-center items-center rotate-180 text-center text-base py-4 border border-gray-700 cursor-pointer")}
                              style={{ writingMode: "vertical-rl" }}
                              onClick={() => handleNotesOpen(truck, day.date, unavailableNotes)}
                            >
                              {truck.name} {displayNoteAlert(day.notes, truck)}
                            </div>

                            <div className="flex divide-x-2 last:border-r-2">
                              {/* Show all the assigned trips for each truck */}
                              {day.trips.map((trip) => {
                                if (trip.truckId === truck.id) {
                                  return (
                                    <TripSlot key={trip.id}
                                      date={day.date}
                                      leadsFromIds={leadsFromIds.data}
                                      trip={trip}
                                      capacity={truck.capacity}
                                      distances={distances}
                                      selectedTrip={selectedTrip}
                                      setSelectedTrip={handleDayTripSelect}
                                      handleSelected={handleSelected}
                                      unavailable={unavailable}
                                      year={yearNumber}
                                      week={weekNumber}
                                    />
                                  )
                                }
                              })}
                              <div className="flex justify-center items-center w-60">
                                <button onClick={() => handleAddTrip(day.date, truck.id)} className="text-2xl bg-green-500 rounded-full text-white hover:bg-green-700">
                                  <PlusIcon className="h-10 w-10" aria-hidden="true" />
                                </button>
                              </div>
                            </div>

                          </div>
                        )
                      })}
                    </div>

                  </div>
                )
              })}
            </div>
          </div>

          {/* Map */}
          <CalendarDayMap leads={dayLeads} selectedLead={selected} />

          <div className="flex gap-x-4">
            {isCollectionNoteLoading ?
              <div className="w-20">
                <LoadingWheel />
              </div>
              :
              <ButtonInput label="Print Collection Notes" isSubmit={false} onClick={() => handleCollectionNotes()} disabled={!selectedDay} classes={!selectedDay && "bg-gray-400 hover:bg-gray-400"} />
            }
            <PrintJobSheet selectedDay={selectedDay} week={weekNumber} year={yearNumber} />
          </div>

        </div>
      </>
    )
  }
}