import { useForm } from "react-hook-form";
import Modal from "../../../layouts/Modal";
import { IPartCategory } from "../../../models/Part";
import { BulkCategoryListingDefaults } from "./BulkCategoryListingDefaults";
import { FormatType, ListingDuration } from "../../../models/EbayOffer";
import BulkCategoryAspectsDefaults from "./BulkCategoryAspectsDefaults";
import { useEffect } from "react";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import useCategoryMutations from "../../../data/useCategoryMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";


const formatOptions = [
  { id: FormatType.Auction, name: "Auction" },
  { id: FormatType.FixedPrice, name: "Buy It Now" },
];

const durationOptions = [
  // { id: ListingDuration.GTC, name: "Until Cancelled" }, //auto selected on 
  { id: ListingDuration.Days1, name: "1 Day" },
  { id: ListingDuration.Days3, name: "3 Days" },
  { id: ListingDuration.Days5, name: "5 Days" },
  { id: ListingDuration.Days7, name: "7 Days" },
  { id: ListingDuration.Days10, name: "10 Days" },
  { id: ListingDuration.Days21, name: "21 Days" },
  { id: ListingDuration.Days30, name: "30 Days" },
];

interface FormValues {
  listingDefaults: {
    width: number,
    height: number,
    length: number,
    weight: number,
    format: FormatType,
    duration: ListingDuration,
    auctionReservePrice: number,
    auctionStartPrice: number,
    buyItNowPrice: number,
    enableBestOffer: boolean,
    autoAcceptPrice: number,
    fulfillmentPolicyId: string,
    paymentPolicyId: string,
    returnPolicyId: string,
    ebayCategoryId: string | undefined,
    ebayStoreCategoryId: string | undefined,
    locationKey: string,
    titleStructure: string,
  },
  assignees: [
    {
      [key: string]: number
    }
  ]
};

export default function BulkUpdateCategoryDefaults({ open, setOpen, selectedCategories }: { open: boolean, setOpen: any, selectedCategories: IPartCategory[] }) {
  const { bulkUpdateDefaultCategories } = useCategoryMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Default Categories");
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm<FormValues>({
    defaultValues: {
      listingDefaults: {
        width: 0,
        height: 0,
        length: 0,
        weight: 0,
        format: FormatType.FixedPrice,
        duration: ListingDuration.GTC,
        auctionReservePrice: 0,
        auctionStartPrice: 0,
        buyItNowPrice: 0,
        enableBestOffer: false,
        autoAcceptPrice: 0,
        fulfillmentPolicyId: "62833190024", //Default to collection only
        paymentPolicyId: "121933662024", //Default to Managed Payments
        returnPolicyId: "204693254024", //Default to 30 day returns
        ebayCategoryId: undefined,
        ebayStoreCategoryId: undefined,
        locationKey: "autosave", //Default to Autosave
        titleStructure: "{{year}} {{make}} {{model}} {{categoryName}}",
      }
    }
  });

  const ebayCategoryIdValue = watch("listingDefaults.ebayCategoryId");

  const onSubmit = (data: FormValues) => {
    //remove any assignees with a value of 0
    const assignees = data.assignees.filter((a) => Object.values(a)[0] !== 0).reduce((acc, curr) => {
      const key = Object.keys(curr)[0]
      const value = Object.values(curr)[0]
      acc[key] = value;
      return acc;
    }, {});

    const body = {
      //array of part category ids
      categoryIds: selectedCategories.map((cat) => cat.id),
      listingDefaults: data.listingDefaults,
      assignees: assignees
    };

    console.log(body);

    bulkUpdateDefaultCategories.mutate(body, saveHandlers);
  };

  useEffect(() => {

  }, [selectedCategories, ebayCategoryIdValue])


  return (
    <Modal open={open} setOpen={setOpen} width="max-w-7xl">
      <form onSubmit={handleSubmit(onSubmit)} >
        <h1 className="text-2xl">Bulk Update eBay Defaults</h1>
        <div className="mt-4 space-y-4 text-xl">
          Categories Selected: <span className="font-semibold">{selectedCategories.map((cat) => cat.name).join(", ")}</span>
        </div>

        <hr className="my-4" />

        <BulkCategoryListingDefaults register={register} getValues={getValues} setValue={setValue} watch={watch} />

        {ebayCategoryIdValue ?
          <BulkCategoryAspectsDefaults ebayCategoryId={ebayCategoryIdValue} register={register} getValues={getValues} setValue={setValue} watch={watch} />
          :
          <div className="mt-6">
            <h1 className="text-lg mb-4">eBay Item Aspect Defaults</h1>
            <div className="border-t border-gray-200 pt-4 flex flex-col gap-4 sm:gap-y-6">
              <p>No eBay Category Selected</p>
            </div>
          </div>
        }

        <div>
          <ButtonInput label="Save" isSubmit={true} classes="mt-4" onClick={undefined} />
        </div>

      </form>
    </Modal>
  )
}